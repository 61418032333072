.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#root {
  background-color: #1844b8;
}
.site-navbar {
  margin-bottom: 0px;
  z-index: 1999;
  position: relative;
  top: 0;
  width: 100%;
  padding: 1rem;
  position: absolute;
  padding-top: 0.5rem;
}
@media (max-width: 991.98px) {
  .site-navbar {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}
.site-navbar .toggle-button {
  position: absolute;
  right: 0px;
}
.site-navbar .site-logo {
  margin: 0;
  padding: 0;
  font-size: 1rem;
}
.site-navbar .site-logo a {
  text-transform: uppercase;
  color: #fff;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
@media (max-width: 991.98px) {
  .site-navbar .site-logo {
    float: left;
    position: relative;
  }
}
.site-navbar .site-navigation.border-bottom {
  border-bottom: 1px solid white !important;
}
.site-navbar .site-navigation .site-menu {
  margin: 0;
  padding: 0;
  margin-bottom: 0;
}
.site-navbar .site-navigation .site-menu .active {
  color: #eb4d55 !important;
}
.site-navbar .site-navigation .site-menu a {
  text-decoration: none !important;
  display: inline-block;
}
.site-navbar .site-navigation .site-menu > li {
  display: inline-block;
}
.site-navbar .site-navigation .site-menu > li > a {
  margin-left: 15px;
  margin-right: 15px;
  padding: 20px 0px;
  font-size: 15px;
  color: rgba(255, 255, 255, 0.7) !important;
  display: inline-block;
  text-decoration: none !important;
  position: relative;
}
.site-navbar .site-navigation .site-menu > li > a:before {
  content: "";
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: 0.3s transform ease;
  -o-transition: 0.3s transform ease;
  transition: 0.3s transform ease;
  background: #fff;
}
.site-navbar .site-navigation .site-menu > li > a:hover {
  color: #fff !important;
}
.site-navbar .site-navigation .site-menu > li > a:hover:before {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.site-navbar .site-navigation .site-menu > li.active > a {
  color: #fff !important;
}
.site-navbar .site-navigation .site-menu > li.active > a:before {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.site-navbar .site-navigation .site-menu .has-children {
  position: relative;
}
.site-navbar .site-navigation .site-menu .has-children > a {
  position: relative;
  padding-right: 20px;
}
.site-navbar .site-navigation .site-menu .has-children > a:before {
  position: absolute;
  content: "\e313";
  font-size: 16px;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: "icomoon";
}
.site-navbar .site-navigation .site-menu .has-children .dropdown {
  visibility: hidden;
  opacity: 0;
  top: 100%;
  position: absolute;
  text-align: left;
  border-top: 2px solid #eb4d55;
  -webkit-box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.1);
  padding: 0px 0;
  margin-top: 20px;
  margin-left: 0px;
  background: #fff;
  -webkit-transition: 0.2s 0s;
  -o-transition: 0.2s 0s;
  transition: 0.2s 0s;
}
.site-navbar .site-navigation .site-menu .has-children .dropdown.arrow-top {
  position: absolute;
}
.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown.arrow-top:before {
  display: none;
  bottom: 100%;
  left: 20%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown.arrow-top:before {
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: #fff;
  border-width: 10px;
  margin-left: -10px;
}
.site-navbar .site-navigation .site-menu .has-children .dropdown a {
  text-transform: none;
  letter-spacing: normal;
  -webkit-transition: 0s all;
  -o-transition: 0s all;
  transition: 0s all;
  color: #000 !important;
}
.site-navbar .site-navigation .site-menu .has-children .dropdown a.active {
  color: #eb4d55 !important;
  background: #f8f9fa;
}
.site-navbar .site-navigation .site-menu .has-children .dropdown > li {
  list-style: none;
  padding: 0;
  margin: 0;
  min-width: 200px;
}
.site-navbar .site-navigation .site-menu .has-children .dropdown > li > a {
  padding: 9px 20px;
  display: block;
}
.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown
  > li
  > a:hover {
  background: #f8f9fa;
  color: #ced4da;
}
.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown
  > li.has-children
  > a:before {
  content: "\e315";
  right: 20px;
}
.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown
  > li.has-children
  > .dropdown,
.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown
  > li.has-children
  > ul {
  left: 100%;
  top: 0;
}
.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown
  > li.has-children:hover
  > a,
.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown
  > li.has-children:active
  > a,
.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown
  > li.has-children:focus
  > a {
  background: #f8f9fa;
  color: #ced4da;
}
.site-navbar .site-navigation .site-menu .has-children:hover > a,
.site-navbar .site-navigation .site-menu .has-children:focus > a,
.site-navbar .site-navigation .site-menu .has-children:active > a {
  color: #eb4d55;
}
.site-navbar .site-navigation .site-menu .has-children:hover,
.site-navbar .site-navigation .site-menu .has-children:focus,
.site-navbar .site-navigation .site-menu .has-children:active {
  cursor: pointer;
}
.site-navbar .site-navigation .site-menu .has-children:hover > .dropdown,
.site-navbar .site-navigation .site-menu .has-children:focus > .dropdown,
.site-navbar .site-navigation .site-menu .has-children:active > .dropdown {
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
  margin-top: 0px;
  visibility: visible;
  opacity: 1;
}
.hero {
  background-image: url("//wotfi.gg/img/banner-1.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 620px;
  padding-top: 205px;
  padding-bottom: 100px;
  overflow: auto;
}
.info {
  background-image: url("//wotfi.gg/img/banner-3.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 620px;
  padding-top: 205px;
  border-right: 2px solid #ffffff;
}
.heroabout {
  background-image: url("//wotfi.gg/img/banner-2.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 400px;
  padding-top: 205px;
  padding-bottom: 100px;
  overflow: auto;
}
.textbox {
  padding: 50px 50px 50px 79px;
  max-width: 810px;
}
.textbox-about {
  padding: 20px 12px 20px 20px;
  max-width: 810px;
}
.textbox-about-top {
  padding: 20px 50px 20px 20px;
  max-width: 810px;
}
@media (min-width: 1320px) {
  .event-container {
    width: 100%;
    margin-left: 0px !important;
  }
}
.footer {
  text-align: center;
  background: #330d38;
  background: -webkit-linear-gradient(left, #330d38 0%, #190d36 100%);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#330d38),
    to(#190d36)
  );
  background: -o-linear-gradient(left, #330d38 0%, #190d36 100%);
  background: linear-gradient(to right, #330d38 0%, #190d36 100%);
  background: -moz-linear-gradient(left, #330d38 0%, #190d36 100%);
}

@media (max-width: 991.98px) {
  .sticky {
    position: relative;
    bottom: 53px;
  }
  .title {
    font-size: 30px;
    margin-top: 5px;
  }
}

@media (max-width: 768.98px) {
  .sticky {
    position: relative;
    bottom: 65px;
  }
  .title {
    font-size: 20px !important;
    margin-top: 5px;
  }
}
.events {
  background-repeat: no-repeat;
  background-position: center center;
  height: 300px;
  padding-top: 205px;
  margin-left: 20px;
  border-left: 2px solid #ffffff;
  background: linear-gradient(rgba(92, 77, 66, 0.4), rgba(92, 77, 66, 0.4)),
    url("//wotfi.gg/img/lan-event.webp") no-repeat center right;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.current-staff {
  color: #fc035a !important;
}
.subdiv {
  color: #00ff91 !important;
}
